export default { 
  homepageUrl: 'https://www.crearistorante.it',
  googleRedirectLink: 'https://apriristorante.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&client_id=5jtff2km8joqfsenugb6h5chkp&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://servizi.crearistorante.it/private/google&identity_provider=Google', 
  apiUrl: 'https://hn9tves31f.execute-api.eu-west-1.amazonaws.com/prod', 
  partners: { 
    apiUrl: 'https://7h9f951c8j.execute-api.eu-west-1.amazonaws.com/prod', 
    publicApiUrl: 'https://5uth4mxkx8.execute-api.eu-west-1.amazonaws.com/prod' 
  },
  cognito: {
    baseWebsite: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_h6fxvcNKi', 
      clientId: '5jtff2km8joqfsenugb6h5chkp'
    },
    partners: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_YaazjqRrs',
      clientId: '7vu8tac8ss9vcspki8e7f0hu34'
    }
  },
  stripe: {
      products: {
        companies: {
          ONE_YEAR: 'https://buy.stripe.com/fZe6rX75XfmL0bCcMO',
          SIX_MONTHS: 'https://buy.stripe.com/fZe7w1cqh3E34rS28b',
          THREE_MONTHS: 'https://buy.stripe.com/00g03zgGx0rR4rScMQ',
          ONE_MONTH: 'https://buy.stripe.com/dR6aId9e5a2r7E46ow'
        },
        freelancers: {
          ONE_YEAR: 'https://buy.stripe.com/5kA9E94XP7Uj1fGbIN',
          SIX_MONTHS: 'https://buy.stripe.com/aEUeYtdul0rR9Mc4gm',
          THREE_MONTHS: 'https://buy.stripe.com/aEUg2x61T4I71fGcMT',
          ONE_MONTH: 'https://buy.stripe.com/fZebMhcqhgqP1fGdQZ'
        }
      }
    }
}
